<template>
    <div id="content" class="col content-wrapper">
        <div class="row row-cols-1 row-cols-md-2 justify-content-center mb-3 text-center">
            <div class="col card-group">
                <div class="card mb-4 rounded-3 shadow-sm border">
                    <div class="card-header py-3 btn-secondary position-relative text-center">
                        <h4 class="my-0 fw-normal fs-5 ">{{ $t('rates.current-bonus-balance') }}</h4>
                        <button
                        class="btn btn-outline-primary position-absolute end-0 top-50 translate-middle-y me-2 custom-btn"
                        :class="{ disabled:loading.balance }"
                        @click="getCurrentBonusBalance"
                    >
                        <i class="bi bi-arrow-clockwise mx-2" v-show="!loading.balance"></i>
                        <span class="loading" v-show="loading.balance"></span>
                    </button>
                    </div>
                    <div class="card-body text-center">
                        <p class="fs-3">{{ currentBonusBalance.toLocaleString('kk-KZ') }} <img class="bonus-coin" src="../../assets/img/coin-1.png"></img></p>
                    </div>
                </div>
            </div>
            <div class="col card-group">
                <div class="card mb-4 rounded-3 shadow-sm border">
                    <div class="card-header py-3 btn-secondary">
                        <h4 class="my-0 fw-normal">Клиенты</h4>
                    </div>
                    <div class="card-body d-flex flex-column">
                        <p class="fs-3">{{ this.clinetsCount }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bonus-coin{
    width: 30px; /* Set width */
    height: 30px; /* Set height */
    margin-top: -4px
}

.btn .loading::after {
    font-size: 1em;
}

.content-wrapper {
    width: 50%;
}

.custom-btn {
  padding: 2px 2px; 
}

</style>

<style>
    #info {
        background-color: #f3f3f3;
    }
    #info p {
       margin: 0;
    }
</style>

<script>

export default {
    name: 'BonusAccount',
    data: () => ({
        loading: {
            bonusBalance: false,
        },
        currentBonusBalance: 0,
        clinetsCount: 200,
        transactions: [
            {
                amount: 100,
                username: "Ерлан",
                type: "claimPaid"
            },
            {
                amount: 10,
                username: "Марат",
                type: "promocode"
            }
        ]
    }),
    computed: {
        standardTitle() {
            return this.$t('rates.standard.title')
        },
        standardDesc() {
            return this.$t('rates.standard.desc')
        },
        enterpriseTitle() {
            return this.$t('rates.enterprise.title')
        },
        enterpriseDesc() {
            return this.$t('rates.enterprise.desc')
        },
        preparedItems() {
            let arr = this.items

            for(let i in arr) {
                let name = arr[i].name.toLowerCase()

                if(name == 'standard') {
                    arr[i].name = this.standardTitle
                    arr[i].desc = this.standardDesc
                }
                
                if(name == 'enterprise') {
                    arr[i].name = this.enterpriseTitle
                    arr[i].desc = this.enterpriseDesc
                }
            }

            return arr
        }
    },
    mounted() {
        this.getCurrentBonusBalance()
        this.$emit('setPageTitle', this.$t('rates.title'))

        let notifyMsg = this.$route.query.notify_msg ?? ''

        if(notifyMsg == 'replenish-account-success') {
            this.$notify({
                title: this.$t('notify.types.success'),
                text: this.$t('rates.notify-msg.replenish-account-success'),
                type: 'success'
            })
        } else if(notifyMsg == 'replenish-account-error') {
            this.$notify({
                title: this.$t('notify.types.error'),
                text: this.$t('rates.notify-msg.replenish-account-error'),
                type: 'error'
            })
        }
    },
    methods: {
        langChange() {
            this.$emit('setPageTitle', this.$t('rates.title'))
        },
        getCurrentBonusBalance() {
            if(this.loading.bonusBalance) return
            this.loading.bonusBalance = true

            let url = process.env.VUE_APP_API_URL + '/bonus-accounts/my'
            axios.get(url, {
                withCredentials: true,
            })
            .then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    let account = r.data;
                    this.currentBonusBalance = account.balance !== null ? account.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0
                }
                this.loading.bonusBalance = false
            })
        }
    }
}  
</script>