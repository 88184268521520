<template>
    <div :id="id" class="row align-items-center mb-3" v-if="currentBonusBalance > 0">
        <div class="col">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" role="switch" id="bonusesCheckbox" v-model="bonusAccountUsage" :disabled="disabled">
                <label class="form-check-label" for="bonusesCheckbox">
                    {{ $t('claims.add.spend-bonuses') }} ({{ currentBonusBalance }})
                </label>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UseBonuses.vue',
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        id: 'UseBonuses',
        currentBonusBalance: 0,
        bonusAccountUsage: false
    }),
    watch: {
        bonusAccountUsage(newVal) {
            this.$emit('updateBonusUsage', newVal) 
        }
    },
    methods: {
        async getCurrentBonusBalance() {
            let url = process.env.VUE_APP_API_URL + '/bonus-accounts/my'

            await axios.get(url, {
                withCredentials: true,
            })
            .then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    let account = r.data;
                    this.currentBonusBalance = account.balance !== null ? account.balance : 0
                }
            })
        }
    },
    mounted() {
        this.getCurrentBonusBalance()
    }
}

</script>

<style>
.form-check-input {
    transform: translateY(2px);
}

.form-check-input[type="checkbox"]:checked {
    background-color: #a6a0fb !important;
    border-color: #a6a0fb !important;
}

.form-check-input[type="checkbox"]:checked:focus {
    background-color: #a6a0fb !important;
    border-color: #a6a0fb !important;
}

.form-switch .form-check-input:checked[type="checkbox"]::after {
    background-color: #a6a0fb !important;
    border-color: #a6a0fb !important;
}
</style>