<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <div class="modal-body">
                    <h5 class="mb-3">{{ $t('claim.send-notifications-modal-title') }}</h5>
                    <div class="p-3">
                        <Select
                            v-model="notificationMethod"
                            :name="'notificationMethodOptionsSelect'"
                            :placeholder="$t('claims.add.notification-label')"
                            :options="notificationMethods"
                            :search="false"
                            :parent="'#' + id"
                            @change="onNotificationMethodChange"
                        />
                    </div>
                    <UseBonuses @updateBonusUsage="handleBonusUsageUpdate"/>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary" v-if="!loading" @click="submit">{{ $t('claim.send-notifications') }}</button>
                        <button type="button" class="btn btn-primary" v-else disabled>
                            <span class="loading d-flex align-items-center"></span>
                        </button>
                        <button type="button" class="btn btn-danger ms-3" data-bs-dismiss="modal" :disabled="loading">{{ $t('btn.cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="d-none" data-bs-dismiss="modal" ref="closeModal"></button>
    </div>
</template>

<script>
import Select from '../form/Select2.vue'
import UseBonuses from './UseBonuses.vue'

export default {
    name: 'SendNotificationsModal',
    data: () => ({
        id: 'sendNotificationsModal',
        loading: false,
        notificationMethod: 0,
        notificationKey: '',
        notificationCost: 0,
        bonusAccountUsage: false
    }),
    props: {
        claimId: {
            type: Number,
            required: true
        },
        notificationMethods: {
            type: Array,
            default: []
        }
    },
    watch: {
        'notificationMethods': 'clearCurrentMethod'
    },
    components: { Select, UseBonuses },
    methods: {
        async submit() {
            if(this.loading) return
            if(!this.notificationMethod) return

            this.close()
            this.$emit('submit', {
                notificationMethod: this.notificationMethod,
                notificationKey: this.notificationKey,
                notificationCost: this.notificationCost,
                bonusAccountUsage: this.bonusAccountUsage
            })
        },
        onNotificationMethodChange(id = '', text = '', { item = {} }) {
            this.notificationKey = item.title
            this.notificationCost = item.cost
        },
        close() {
            this.$refs.closeModal.click()
        },
        handleBonusUsageUpdate(value) {
            this.bonusAccountUsage = value
            this.$emit('refreshNotificationMethods', this.bonusAccountUsage)
        },
        clearCurrentMethod() {
            this.notificationMethod = 0
        }
    },
}

</script>