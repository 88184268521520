<template>
    <div id="content" class="col">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="claim-header">
            <span class="claim-title"></span>
            <span class="badge rounded-pill status-text" :class="`status-text-` + this.claim.status">{{ this.claim.statusText }}</span>
        </div>
        <div class="row">
            <div class="col-md-7">
                <ClaimText v-if="loaded" :text="claim.content" :claim="claim" :template="claim.template" />
            </div>
            <div class="col-md-5">
                <div class="shadow p-3">
                    <button
                        type="button"
                        class="btn btn-lg btn-secondary w-100 mb-2"
                        :class="{ loading: actionLoading }"
                        :disabled="actionLoading"
                        @click="updateContent"
                    >
                        {{ $t('claim.btn-update') }}
                        <i class="bi bi-arrow-counterclockwise"></i>
                    </button>
                    <ul class="nav nav-tabs" id="claimTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="actions-tab" data-bs-toggle="tab" data-bs-target="#actions-tab-pane" type="button" role="tab" aria-controls="actions-tab-pane" aria-selected="true">{{ $t('claim.tab-actions') }}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history-tab-pane" type="button" role="tab" aria-controls="history-tab-pane" aria-selected="false">{{ $t('claim.tab-history') }}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="claimTabContent">
                        <div class="tab-pane fade show active" id="actions-tab-pane" role="tabpanel" aria-labelledby="actions-tab" tabindex="0">
                            <PayBlock
                                ref="payblock"
                                :html-text="$t('claim.pay-block.html-text', { amount: getAmountBefore(), amount_after: getAmountAfter() })"
                                :show-button="this.claim.canPay"
                                @clicked="handlePayBtnClick"
                            />
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-lg btn-success w-100 mb-2"
                                        type="button"
                                        v-if="!this.claim.ownClaim && showAcceptBtn"
                                        :class="{ loading: actionLoading }"
                                        :disabled="this.claim.status>1 || actionLoading"
                                        :style="{ display: btnDisplay }"
                                        @click="accept()"
                                    >
                                        {{ $t('claim.btn-accept') }}
                                        <i class="bi bi-file-earmark-check-fill"></i>
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-lg btn-danger w-100 mb-2"
                                        type="button"
                                        v-if="!this.claim.ownClaim && showDeclineBtn"
                                        :class="{ loading: actionLoading }"
                                        :disabled="this.claim.status>1 || actionLoading"
                                        :style="{ display: btnDisplay }"
                                        @click="showDeclineModal()"
                                    >
                                        {{ $t('claim.btn-decline') }}
                                        <i class="bi bi-file-earmark-excel-fill"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="text-center" v-if="loaded && this.claim.canSendNotifications">
                                <button
                                    type="button"
                                    class="btn btn-success w-100 mb-2"
                                    v-show="!sendNotificationLoading"
                                    @click="showSendNotificationsModal()"
                                    :disabled="!canShowSendNotificationsBtn">
                                    {{ $t('claim.btn-send-notifications') }}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-success w-100 mb-2"
                                    v-show="sendNotificationLoading"
                                    :disabled="!canShowSendNotificationsBtn">
                                    <span class="loading d-flex align-items-center justify-content-center"></span>
                                </button>
                                <small class="text-danger" v-if="!canShowSendNotificationsBtn">{{ $t('claim.need-more-balance') }}</small>
    
                                <hr />
                            </div>

                            <button
                                class="btn btn-secondary w-100 mb-2"
                                type="button"
                                v-if="this.claim.canGetPdf"
                                @click="getPdf()">
                                {{ $t('claim.btn-pdf') }}
                            </button>
                            <button
                                v-if="this.claim.canGetHistory"
                                class="btn btn-secondary w-100 mb-2"
                                type="button"
                                @click="getHistory()"
                            >
                                {{ $t('claim.btn-get-history') }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-secondary w-100 mb-2"
                                v-if="this.claim.canGetSignedPdf"
                                @click="getSignedPdf()"
                            >
                                {{ $t('claim.btn-signed-pdf') }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-secondary w-100 mb-2"
                                v-if="this.claim.canGetHistoryCMS"
                                @click="getHistory(true)"
                            >
                                {{ $t('claim.btn-get-history-cms') }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-warning w-100 mb-2"
                                v-if="this.claim.canChat"
                                @click="handleSendMessageBtn"
                            >
                                {{ $t('claim.btn-chat') }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger w-100 mb-2"
                                v-if="this.claim.ownClaim&&(this.claim.status==0||this.claim.status==1||this.claim.status==2||this.claim.status==3||this.claim.status==7)&&showWithdrawBtn"
                                @click="showWithdrawModal()"
                            >
                                {{ $t('claim.btn-withdraw') }}
                            </button>
                        </div>
                        <div class="tab-pane fade" id="history-tab-pane" role="tabpanel" aria-labelledby="history-tab" tabindex="0">
                            <div class="history-item" v-for="h in claim.history ?? []">
                                <span class="history-event">{{ h.human_event }}</span>
                                <span class="history-date">{{ h.human_date }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="decline-reason" v-show="claim.decline_reason">
                    <h4>{{ $t('claim.decline-title') }}</h4>
                    <div class="shadow p-3 my-3">
                        <p>{{ declineReasons[claim.decline_reason] ?? declineReasons.other }}</p>
                    </div>
                </div>
                <div class="payment-receipt-upload" v-if="claim.canPaymentReceiptUpload">
                    <h4 v-html="$t('claim.payment-receipt-upload.title')"></h4>
                    <div class="shadow p-3 my-3">
                        <p v-html="$t('claim.payment-receipt-upload.desc')"></p>
                        <FileUpload
                            ref="paymentReceiptUploadFile"
                            @added="handlePaymentReceiptUploadAdded"
                            @removed="handlePaymentReceiptUploadRemoved"
                        />
                        <button
                            type="button"
                            class="btn btn-primary w-100 mb-2"
                            v-if="showAttachPaymentReceiptButton"
                            @click="attachPaymentReceipt"
                        >
                            {{ $t('claim.payment-receipt-upload.btn-attach') }}
                        </button>
                    </div>
                </div>
                <div class="claim-files" v-show="files.length || loadingFiles">
                    <h4>{{ $t('claim.files.title') }}</h4>
                    <div class="shadow p-3 my-4">
                        <table class="table">
                            <thead>
                                <tr v-show="files.length && !loadingFiles">
                                    <th>{{ $t('claim.files.table-col-1') }}</th>
                                    <th>{{ $t('claim.files.table-col-2') }}</th>
                                    <th>{{ $t('claim.files.table-col-3') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(file, index) in files" v-show="files.length && !loadingFiles">
                                    <td>
                                        <button @click="showPreview(index)" class="file-link" :title="cutFileTitle(file.title, 1000)">{{ cutFileTitle(file.title, 30) }}</button>
                                    </td>
                                    <td>
                                        <i class="bi fs-5" :class="'bi-filetype-' + file.extension"></i>
                                    </td>
                                    <td>
                                        <a :href="getFileUrl(file.path)" class="file-link me-3" target="_blank"><span class="bi bi-download fs-5"></span></a>
                                        <span class="trash-button" v-show="this.claim.status == -1" @click="removeFile(file.id)"><i class="bi bi-trash3 fs-5"></i></span>
                                    </td>
                                </tr>
                                <tr v-show="!files.length && !loadingFiles">
                                    <td colspan="3">{{ $t('claim.files.no-files') }}</td>
                                </tr>
                                <tr v-show="loadingFiles">
                                    <td colspan="3" class="text-center">
                                        <div class="loading"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FsLightbox
        :toggler="toggler"
        :slide="slide"
        :sources="srcObjs"
    />
    <ChoiceContactsModal
        ref="choiceContactsModal"
        :emails-limit="contactsLimits.emails ?? 0"
        :phones-limit="contactsLimits.phones ?? 0"
        :addresses-limit="contactsLimits.addresses ?? 0"
        @selected="handleChoiceContctsSelected"
        @closed="handleChoiceContctsClosed"
    />
    <ConfirmRemoveModal @removeClaim="remove" />
    <ConfirmDeclineModal @declineClaim="decline" :claimSlug="this.slug" @declined="getClaim" />
    <ConfirmWithdrawModal @withdrawClaim="withdraw" />
    <SendNotificationsModal
        ref="sendNotificationsModal"
        :notification-methods="notificationMethods"
        @submit="onSubmitSendNotifications"
        @refreshNotificationMethods="refreshNotificationMethods"
    />
    <SignModal :claimSlug="$route.params.slug" @signed="signed" />
</template>

<script>

    import FsLightbox from "fslightbox-vue/v3"
    import CustomSource from "../CustomSource.vue"

    import Form from './Form.vue'
    import FileUpload from '../form/FileUpload.vue'
    import ChoiceContactsModal from './ChoiceContactsModal.vue'
    import ClaimText from './ClaimText.vue'
    import ConfirmDeclineModal from './ConfirmDeclineModal.vue'
    import ConfirmRemoveModal from './ConfirmRemoveModal.vue'
    import ConfirmWithdrawModal from './ConfirmWithdrawModal.vue'
    import SendNotificationsModal from './SendNotificationsModal.vue'
    import SignModal from './SignModal.vue'
    import PayBlock from '../cabinet/PayBlock.vue'

    export default {
        data: () => ({
            id: 0,
            slug: '',
            claim: false,
            files: [],
            loadingFiles: false,
            loaded: false,
            toggler: false,
            srcObjs: [],
            slide: 1,
            showAttachPaymentReceiptButton: false,
            showSignBtn: true,
            showAcceptBtn: true,
            showDeclineBtn: true,
            showWithdrawBtn: true,
            btnDisplay: 'none',
            actionLoading: false,
            currentBalance: 0,
            currentBonusBalance: 0,
            bonusAccountUsage: false,
            sendNotificationLoading: false,
            notificationCost: 0,
            notificationMethod: 0,
            notificationMethods: [],
            contactsLimits: { emails: 0, phones: 0, addresses: 0 },
            contactsLimitsObj: {
                free: { emails: 0, phones: 0, addresses: 0 }
            },
            minCostForSendNotifications: 0,
            recipient: null
        }),
        computed: {
            fileTypes: {
                get() {
                    return {
                        image: this.$t('files.types.image'),
                        other: this.$t('files.types.other')
                    }
                }
            },
            statuses: {
                get() {
                    return {
                        'draft': this.$t('claim.statuses.draft'),
                        'unread': this.$t('claim.statuses.unread'),
                        'read': this.$t('claim.statuses.read'),
                        'disputed': this.$t('claim.statuses.disputed'),
                        'declined': this.$t('claim.statuses.declined'),
                        'accepted': this.$t('claim.statuses.accepted'),
                        'withdrawn': this.$t('claim.statuses.withdrawn'),
                        'scheduled': this.$t('claim.statuses.scheduled'),
                        'expired': this.$t('claim.statuses.expired'),
                        'paid': this.$t('claim.statuses.paid'),
                        'unknown': this.$t('claim.statuses.unknown'),
                        'badge_draft': this.$t('claim.statuses.badge_draft'),
                        'badge_unread': this.$t('claim.statuses.badge_unread'),
                        'badge_read': this.$t('claim.statuses.badge_read'),
                        'badge_disputed': this.$t('claim.statuses.badge_disputed'),
                        'badge_declined': this.$t('claim.statuses.badge_declined'),
                        'badge_accepted': this.$t('claim.statuses.badge_accepted'),
                        'badge_withdrawn': this.$t('claim.statuses.badge_withdrawn'),
                        'badge_paid': this.$t('claim.statuses.badge_paid'),
                        'badge_scheduled': this.$t('claim.statuses.badge_scheduled'),
                        'badge_expired': this.$t('claim.statuses.badge_expired'),
                        'badge_unknown': this.$t('claim.statuses.badge_unknown')
                    }
                }
            },
            declineReasons: {
                get() {
                    return {
                        disagree: this.$t('claim.decline-reasons.disagree'),
                        mistake: this.$t('claim.decline-reasons.mistake'),
                        other: this.$t('claim.decline-reasons.other')
                    }
                }
            },
            totalOwed: {
                get() {
                    if(!this.claim) return 0

                    let total = parseFloat(this.claim.amount_owed)

                    if(this.claim.template_fields.amount_of_penalty) total += parseFloat(this.claim.template_fields.amount_of_penalty.field_value)

                    if(this.claim.template_fields.contract_penalty) total += parseFloat(this.claim.template_fields.contract_penalty.field_value)

                    if(this.claim.template_fields.deposit_additional_costs) total += parseFloat(this.claim.template_fields.deposit_additional_costs.field_value)

                    return total
                }
            },
            canShowSendNotificationsBtn: {
                get() {
                    return parseFloat(this.currentBalance) + parseFloat(this.currentBonusBalance) >= parseFloat(this.minCostForSendNotifications)
                }
            }
        },
        components: {
            Form,
            FileUpload,
            ChoiceContactsModal,
            ClaimText,
            FsLightbox,
            ConfirmDeclineModal,
            ConfirmRemoveModal,
            ConfirmWithdrawModal,
            SendNotificationsModal,
            SignModal,
            PayBlock
        },
        created() {
            this.showSignBtn = this.$store.state.user.settings.canSignFromCompany ?? true
            this.showAcceptBtn = this.$store.state.user.settings.canSignFromCompany ?? true
            this.showDeclineBtn = this.$store.state.user.settings.canSignFromCompany ?? true

            this.CustomSource = CustomSource
            this.getSources()
        },
        mounted() {
            this.updateContent()
            this.$emit('updateBalance')
            this.getAttachPaymentReceiptButtonStatus()
        },
        watch: {
            '$route.params.slug': 'updateContent',
            'bonusAccountUsage': 'getNotificationMethods'
        },
        methods: {
            updateContent() {
                this.slug = this.$route.params.slug
                this.getClaim()
                this.getNotificationMethods()
                this.actionLoading = false
                this.$emit('setPageTitle', this.$t('claim.title'))
            },
            langChange() {
                this.$emit('setPageTitle', this.$t('claim.title'))
            },
            async read() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/read'

                await axios.get(url, {
                    params: {
                        currentUserId: this.$store.state.user.id
                    },
                    withCredentials: true
                }).then(function (response) {
                }).catch(function (err) {
                    if(process.env.VUE_APP_DEBUG) console.log(err)
                })

                this.$emit('updateCounters')

                this.getClaim()
            },
            async accept() {
                this.actionLoading = true
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/accept'

                await axios.get(url, {
                    withCredentials: true
                }).then(function (response) {
                }).catch(function (err) {
                    if(process.env.VUE_APP_DEBUG) console.log(err)
                })

                this.actionLoading = false
                this.getClaim()
            },
            async dispute() {
                this.actionLoading = true
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/dispute'

                await axios.get(url, {
                    withCredentials: true
                }).then(function (response) {
                    this.actionLoading = false
                }).catch(function (err) {
                    if(process.env.VUE_APP_DEBUG) console.log(err)
                })

                this.getClaim()
            },
            async decline(reason) {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/decline'

                await axios.post(url, {
                    text: reason
                }, {
                    withCredentials: true
                }).then(function (response) {
                }).catch(function (err) {
                    if(process.env.VUE_APP_DEBUG) console.log(err)
                })

                this.actionLoading = false

                this.getClaim()
            },
            async send() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/send'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                }).catch((err) => {
                    console.err(response)
                })

                this.getClaim()
            },
            async withdraw(reason) {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/withdraw'

                await axios.post(url, {
                    reason: reason
                }, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === "SUCCESS") {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: 'Претензия успешно отозвана',
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: r.message,
                            type: 'error'
                        })
                    }
                })

                this.getClaim()
            },
            async signed() {
                this.send()
            },
            async handlePayBtnClick() {
                if(this.$refs.payblock.loading) return
                this.$refs.payblock.setLoading(true)

                let r = await this.$api.claimPayment(this.slug)

                if(r.data && r.data.redirect_url) {
                    window.open(r.data.redirect_url, 'noreferrer')
                }

                this.$refs.payblock.setLoading(false)
            },
            async getPdf() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/pdf'
                window.open(url, '_blank', 'noreferrer')
            },
            async getSignedPdf() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/signed-pdf'
                window.open(url, '_blank', 'noreferrer')
            },
            async getHistory(signed = false) {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/'

                url += signed ? 'signed-history' : 'history'

                window.open(url, '_blank', 'noreferrer')
            },
            async remove() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug + '/remove'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                }).catch((err) => {
                    console.err(response)
                })

                this.$emit('updateCounters')

                this.$router.push('/claims/outbox')
            },
            getClaim() {
                this.loadingFiles = true

                let url = process.env.VUE_APP_API_URL + '/claims/' + this.slug

                axios.get(url, {
                    params: {
                        currentUserId: this.$store.state.user.id
                    },
                    withCredentials: true
                }).then(async (response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.claim = r.data

                        if(this.claim) {
                            this.id = this.claim.id
                            // translate claim status
                            this.claim.statusText = this.claim.statusText in this.statuses ? this.statuses[this.claim.statusText] : this.statuses.unknown

                            // set default template, if not exists
                            if(!(this.claim.template ?? false)) this.claim.template = 'default'

                            if(this.claim.canRead ?? false) this.read()

                            if(this.$store.state.user.settings.company) {
                                if(this.$store.state.user.settings.company.bin === r.data.sender.company_id_num) {
                                    this.claim.ownClaim = true

                                    this.showWithdrawBtn = this.$store.state.user.settings.canSignFromCompany ?? false
                                }
                            } else {
                                this.showWithdrawBtn = this.claim.ownClaim ?? false
                            }
                        }

                        this.loadingFiles = false

                        this.getFiles()

                        // claim text was loaded
                        this.loaded = true

                        // shows action buttons after claim is loaded
                        if(!this.claim.ownClaim) {
                            this.btnDisplay = 'initial'
                        }

                        // read after loading
                        if(this.claim.canRead) await this.read()

                        await this.getRecipient()
                    } else {
                        this.loadingFiles = false

                        this.$router.go(-1)
                        
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claim.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            async getContactsLimits() {
                let contactsLimitsObj = await this.$api.getContactsLimits()

                if(contactsLimitsObj.result == 'SUCCESS') {
                    this.contactsLimitsObj = contactsLimitsObj.data
                }
            },
            async getCurrentBalance() {
                const r = await this.$api.getCurrentBalance()

                if(r.result === 'SUCCESS') {
                    let account = r.data;
                    this.currentBalance = account.balance !== null ? account.balance : 0
                }
            },
            async getCurrentBonusBalance() {
                let url = process.env.VUE_APP_API_URL + '/bonus-accounts/my'

                await axios.get(url, {
                    withCredentials: true,
                })
                .then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        let account = r.data;
                        this.currentBonusBalance = account.balance !== null ? account.balance : 0
                    }
                })
            },
            async getNotificationMethods() {
                this.notificationMethods = []

                await this.getCurrentBalance()
                await this.getCurrentBonusBalance()
                await this.getContactsLimits()

                const r = await this.$api.getNotificationMethods(1)

                if(r.result == 'SUCCESS' && r.data) {
                    for(let i in r.data) {
                        let nMethod = {
                            id: r.data[i].id,
                            text: r.data[i].title,
                            title: r.data[i].title,
                            cost: r.data[i].cost
                        }

                        if(this.bonusAccountUsage == true) {
                            nMethod.disabled = parseFloat(this.currentBalance) + parseFloat(this.currentBonusBalance) < parseFloat(r.data[i].cost)
                        }
                        else {
                            nMethod.disabled = parseFloat(this.currentBalance) < parseFloat(r.data[i].cost)
                        }

                        if(nMethod.text == 'free') nMethod.text = this.$t('claims.add.delivery-methods.free')
                        if(nMethod.text == 'electronic') nMethod.text = this.$t('claims.add.delivery-methods.paid', { price: nMethod.cost })
                        if(nMethod.text == 'kazpost') nMethod.text = this.$t('claims.add.delivery-methods.kazpost', { price: nMethod.cost })
                        if(nMethod.text == 'only_kazpost') nMethod.text = this.$t('claims.add.delivery-methods.only_kazpost', { price: nMethod.cost })

                        this.notificationMethods.push(nMethod)

                        if(this.notificationMethod == 0) this.notificationMethod = nMethod.id
                        if(this.minCostForSendNotifications == 0 || this.minCostForSendNotifications > nMethod.cost) this.minCostForSendNotifications = nMethod.cost
                    }
                }

                if(process.env.VUE_APP_DEBUG) console.log('getNotificationMethods', this.notificationMethods, r)

                return this.notificationMethods
            },
            getAmountBefore() {
                return this.totalOwed ? this.totalOwed.toLocaleString('kk-KZ') : ''
            },
            getAmountAfter() {
                let fiz = false
                fiz = !this.claim.recipient?.company_id_num

                return this.$libs.debt.getTotal(this.totalOwed, fiz).toLocaleString('kk-KZ')
            },
            getFiles() {
                this.loadingFiles = true

                this.files = this.claim.attachmentsData ?? []
                
                this.getSources()

                this.loadingFiles = false
            },
            getFileUrl(path) {
                path = path.replace('uploads/', '')
                path = path.replace('uploads', '')

                return process.env.VUE_APP_API_URL + '/uploads/' + path
            },
            getFileType(filetype) {
                let t = filetype.split('/').shift()

                if(t in this.fileTypes) {
                    return this.fileTypes[t]
                }

                return this.fileTypes.other
            },
            getFileExt(path) {
                return path.split('.').pop()
            },
            async removeFile(fileId) {
                let url = process.env.VUE_APP_API_URL + `/files/${fileId}/remove/${this.claim.id}`

                await axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: 'Файл удален',
                            type: 'success'
                        })

                        this.getClaim()
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: 'Не удалось удалить файл',
                            type: 'error'
                        })
                    }
                })
            },
            async getRecipient() {
                let r = await this.$api.getRecipient(this.claim.recipient.id)

                if(r.result === 'SUCCESS') {
                    this.recipient = r.data
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('recipient.notify.error'),
                        type: 'error'
                    })
                }
            },
            showPreview(index) {
                this.slide = index + 1
                
                this.toggler = !this.toggler
            },
            cutFileTitle(title, len = 30) {
                if(!title) return title

                if(title.length <= len) return title

                return title.slice(0, len) + '...'
            },
            getSources() {
                this.srcObjs = []
                
                if(this.files) {
                    for(let f of this.files) {
                        let name = f.path
                        name = name.replace('uploads/', '')
                        name = name.replace('uploads', '')
    
                        let i = process.env.VUE_APP_API_URL + '/preview/' + name
    
                        this.srcObjs.push({
                            component: CustomSource,
                            props: { src: i}
                        })
                    }
                }
            },
            onSubmitSendNotifications({ notificationMethod, notificationKey, notificationCost, bonusAccountUsage = false }) {
                this.notificationMethod = notificationMethod
                this.contactsLimits = this.contactsLimitsObj[notificationKey]
                this.notificationCost = notificationCost
                this.bonusAccountUsage = bonusAccountUsage

                this.showChoiceContactsModal()
            },
            async sendNotifications(contacts) {
                if(this.sendNotificationLoading) return
                this.sendNotificationLoading = true

                let r = await this.$api.sendClaimNotifications(this.slug, this.notificationMethod, contacts, this.bonusAccountUsage)

                this.sendNotificationLoading = false

                if(r.result === 'SUCCESS') {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('claim.send-notifications-success'),
                        type: 'success'
                    })

                    this.$emit('updateBalance')
                    this.getClaim()
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claim.send-notifications-error'),
                        type: 'error'
                    })
                }

                await this.getCurrentBonusBalance()
            },
            showChoiceContactsModal() {
                if(parseFloat(this.currentBalance) + parseFloat(this.currentBonusBalance) < parseFloat(this.notificationCost)) {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claim.need-more-balance'),
                        type: 'error'
                    })
                } else {
                    this.$refs.choiceContactsModal.clear()
                    this.$refs.choiceContactsModal.contacts = this.recipient.contacts_send
                    this.$store.commit('modalShow', { id: 'ChoiceContactsModal' })
                }
            },
            showDeclineModal() {
                this.$store.commit('modalShow', { id: 'confirmDeclineModal'})
            },
            showRemoveModal() {
                this.$store.commit('modalShow', { id: 'confirmRemoveModal'})
            },
            showWithdrawModal() {
                this.$store.commit('modalShow', { id: 'confirmWithdrawModal'})
            },
            showSignModal() {
                this.$store.commit('modalShow', { id: 'signModal'})
            },
            showSendNotificationsModal() {
                if(parseFloat(this.currentBalance) + parseFloat(this.currentBonusBalance) < parseFloat(this.minCostForSendNotifications)) {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claim.need-more-balance'),
                        type: 'error'
                    })
                    return
                }

                this.$store.commit('modalShow', { id: 'sendNotificationsModal'})
            },
            handleChoiceContctsSelected(contacts) {
                this.sendNotifications(contacts)
            },
            handleSendMessageBtn() {
                this.$router.push(`/claims/${this.slug}/pretrial-agreement`)
            },
            async handlePaymentReceiptUploadAdded(files) {
                if(!files.length || !files[0]) {
                    return
                }

                let formData = new FormData()
                formData.append('file', files[0], files[0].name)

                let upload = await this.$api.uploadFiles(formData)

                if(process.env.VUE_APP_DEBUG) console.log('handlePaymentReceiptUploadAdded', upload)

                if(upload.result == 'SUCCESS') {
                    this.$refs.paymentReceiptUploadFile.files.push({
                        id: upload.file_id,
                        name: upload.originalname
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claim.payment-receipt-upload.notify.upload-error'),
                        type: 'error'
                    })
                }

                this.getAttachPaymentReceiptButtonStatus()
            },
            async handlePaymentReceiptUploadRemoved(fileId) {
                let remove = await this.$api.removeFile(fileId)
                if(process.env.VUE_APP_DEBUG) console.log('handlePaymentReceiptUploadRemoved', remove)

                if(remove.result == 'SUCCESS') {
                    let files = this.$refs.paymentReceiptUploadFile.files.filter(f => f.id != fileId)
                    this.$refs.paymentReceiptUploadFile.files = files
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claim.payment-receipt-upload.notify.remove-error'),
                        type: 'error'
                    })
                }

                this.getAttachPaymentReceiptButtonStatus()
            },
            getAttachPaymentReceiptButtonStatus() {
                this.showAttachPaymentReceiptButton = !!(this.$refs.paymentReceiptUploadFile?.files.length ?? 0)
            },
            async attachPaymentReceipt() {
                if(!this.showAttachPaymentReceiptButton) return

                let filesLen = this.$refs.paymentReceiptUploadFile?.files.length ?? 0
                if(filesLen < 1) return

                let file_id = this.$refs.paymentReceiptUploadFile.files[0].id
                let attach = await this.$api.attachClaimPaymentReceipt(this.slug, { file_id })

                if(attach.result == 'SUCCESS') {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('claim.payment-receipt-upload.notify.attach-success'),
                        type: 'success'
                    })

                    this.showAttachPaymentReceiptButton = false
                    this.getClaim()
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claim.payment-receipt-upload.notify.attach-error'),
                        type: 'error'
                    })
                }
            },
            refreshNotificationMethods(bonusAccountUsage) {
                this.bonusAccountUsage = bonusAccountUsage
            }
        }
    }

</script>

<style scoped>
#claimTab .nav-item {
    flex: auto;
}

#claimTab .nav-item .nav-link {
    width: 100%;
    padding: 1em;
    color: #261D58;
    background-color: #E1E1EC;
    border: none;
}

#claimTab .nav-item:first-child .nav-link {
    border-radius: 1em 0 0 1em;
}

#claimTab .nav-item:last-child .nav-link {
    border-radius: 0 1em 1em 0;
}

#claimTab .nav-item .nav-link.active {
    width: 100%;
    color: #FFFFFF;
    background-color: #BFBCF4;
}

.decline-reason {
    margin: 1.5em 0;
}

.payment-receipt-upload {
    margin: 1.5em 0;
}

.claim-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}

.claim-title {
    margin-right: 1rem;
    font-size: 1.75rem;
    font-weight: 500;
}

.status-text {
    color: #ffffff;
    background-color: #8f82d8;
    font-size: .85rem;
}

.status-text-0 {
    background-color: #cccccc;
}

.status-text-1 {
    background-color: #8f82d8;
}

.status-text-2,
.status-text-6 {
    background-color: #55cb85;
}

.status-text-3,
.status-text-4 {
    background-color: #dc4c64;
}

.claim-files {
    margin: 1rem 0;
}

.claim-files table td {
    vertical-align: middle;
}

.claim-file {
    margin: 1rem;
    text-align: center;
}

.file-link {
    color: #8f82d8;
    border: none;
    background-color: #fff;
}

.trash-button {
    margin-right: .5em;
    color: #8f82d8;
    cursor: pointer;
}

.bi-download {
    color: #8f82d8;
}

.history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
    padding: .75em 1em;
    color: #000000;
    background-color: #F5F5F5;
    border-radius: 1em;
}

.history-event {
    color: #000000;
    text-transform: lowercase;
}

.history-event:first-letter {
    text-transform: capitalize;
}

.history-date {
    flex: none;
    color: #BFBCF4;
}

.loading {
    padding: 0;
}

.btn:disabled {
    color: #eeeeee;
}

</style>